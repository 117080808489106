import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';

import styles from './Placeholder.module.scss';

const Placeholder = () => {
    const { t } = useTranslation();

    const title = t('TOT - Twój CRM do zarządzania relacjami z klientem');
    const description = t('System do zarządzania relacjami z klientami dla usług cyklicznych.');
    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta property="og:locale" content="pl_PL" />
                <meta property="og:type" content="Maintenance" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:url" content={process.env.REACT_APP_LANGUAGE} />
                <meta property="og:site_name" content={process.env.REACT_APP_NAME} />
                <meta property="og:image" content={`${process.env.REACT_APP_URL}assets/poster.jpg`} />
                <meta property="og:image:width" content="980" />
                <meta property="og:image:height" content="690" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content={`${process.env.REACT_APP_URL}assets/poster.jpg`} />
            </Helmet>
            <main className={styles.placeholderSection}>
                <div className={classNames(styles.boxes, styles['boxes-top-left'])}>
                    <div className={classNames(styles.box, styles['box-blue'])}></div>
                    <div className={classNames(styles.box, styles['box-green'])}></div>
                    <div className={classNames(styles.box, styles['box-darkblue'])}></div>
                </div>

                <div className={classNames(styles.boxes, styles['boxes-bottom-right'])}>
                    <div className={classNames(styles.box, styles['box-sm'], styles['box-blue'])}></div>
                    <div className={classNames(styles.box, styles['box-sm'], styles['box-green'])}></div>
                    <div className={classNames(styles.box, styles['box-sm'], styles['box-darkblue'])}></div>
                </div>
                <div className={styles.placeholderContent}>
                    <Logo className={styles.logo} alt="TOT" />
                    <h1>{t('Twój CRM do zarządzania relacjami z klientem.')}</h1>
                    <br />
                    <p>
                        <strong>{t('Wkrótce ruszamy!')}</strong>
                    </p>
                </div>
            </main>
        </>
    );
};

export default Placeholder;
