import React from 'react';
import { Helmet } from 'react-helmet';

import Placeholder from '../../pages/Placeholder';

import i18n from '../../config/i18n';
import '../../styles/style.scss';

i18n(process.env.REACT_APP_LANGUAGE || 'pl');

const App = () => {
    return (
        <>
            <Helmet>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
                <link
                    href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap"
                    rel="stylesheet"
                />
                <meta name="robots" content="index,follow" />
            </Helmet>
            <Placeholder />
        </>
    );
};

export default App;
