import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as resources from '../i18n';

export default async (language = 'pl') => {
    return i18n.use(initReactI18next).init({
        resources,
        lng: language,

        nsSeparator: false,
        keySeparator: false,

        interpolation: {
            escapeValue: false,
        },
    });
};
